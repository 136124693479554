<template>
	<div class="grid">

  <div class="col-12">
			<div class="card">
                <!--Resposible of success and error message-->
                <Toast/>

				<h5>Sample rate Master</h5>

               <DataTable ref="dt" :value="products"  class="p-datatable-gridlines"    :loading="loading2"  responsiveLayout="scroll" >
					
					<template #header>						 
                        <div class="flex justify-content-between flex-column sm:flex-row">
                            <div>
                                    <Button :disabled='new_disabled' label="New" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
                                    <Button label="Delete" icon="pi pi-trash" class="p-button-danger" :disabled="true" />
                            </div> 
                            <div class="mb-2">
                                <Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"  />
                            </div>
                        </div> 
					</template>
                    
                    <template #empty>
                        No data found.
                    </template>
                    <template #loading>
                        Loading  data. Please wait.
                    </template>

                    <Column field="B4" header="Action" style="min-width:8rem">
						<template #body="{data}">
							<Button v-if="productIdEdit!=data.A4" icon="pi pi-pencil" class="p-button-rounded p-button-text  p-button-success mr-2" @click="editProduct(data.A4)" />
                            <Button v-if="productIdEdit==data.A4" icon="pi pi-check" class="p-button-rounded p-button-text mr-2" @click="saveProduct"/>
                            <Button v-if="productIdEdit==data.A4" icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text mr-2"  @click="cancelEdit()"/>
                            <Button v-if="productIdEdit!=data.A4" icon="pi pi-trash" class="p-button-rounded p-button-text  p-button-warning" @click="confirmDeleteProduct(data.A4)" />
						</template>
					</Column>
                    <Column field="B4" header="Name" :style="{'min-width':'6rem','color':'blue'}" frozen>
                        <template #body="{data}">
							<div class="formgrid grid p-fluid"  v-if="productIdEdit==data.A4">
                               <div class="col-12">
                                   <InputText v-model="data.B4"  ></InputText>
                                </div>
                            </div>
                            <div v-else>
                                {{data.B4}}
                            </div>
						</template>
                    </Column>
                    <Column field="C4" header="Description" :style="{'min-width':'6rem','color':'blue'}">
                        <template #body="{data}">
							<div class="formgrid grid p-fluid"  v-if="productIdEdit==data.A4">
                               <div class="col-12">
                                   <InputText v-model="data.C4"  mode="decimal" :minFractionDigits="2" :maxFractionDigits="2"></InputText>
                                </div>
                            </div>
                            <div v-else>
                                {{data.C4}}
                            </div>
						</template>
                    </Column>
                    <Column field="D4" header="DD weight" :style="{'min-width':'7rem','color':'blue'}">
                        <template #body="{data}">
							<div class="formgrid grid p-fluid"  v-if="productIdEdit==data.A4">
                               <div class="col-12">
                                   <InputNumber v-model="data.D4"  mode="decimal" :minFractionDigits="2" :maxFractionDigits="2"></InputNumber>
                                </div>
                            </div>
                            <div v-else>
                                {{parseFloat(data.D4).toFixed(2)}}
                            </div>
						</template>
                    </Column>
                    <Column field="E4" header="DD area" :style="{'min-width':'7rem','color':'blue'}">
                        <template #body="{data}">
							<div class="formgrid grid p-fluid"  v-if="productIdEdit==data.A4">
                               <div class="col-12">
                                   <InputNumber v-model="data.E4"  mode="decimal" :minFractionDigits="2" :maxFractionDigits="4"></InputNumber>
                                </div>
                            </div>
                            <div v-else>
                                {{parseFloat(parseFloat(data.E4).toFixed(4))}}
                            </div>
						</template>
                    </Column>
                    <Column field="F4" header="Density" :style="{'min-width':'6rem','color':'blue'}">
                        <template #body="{data}">
							<div class="formgrid grid p-fluid"  v-if="productIdEdit==data.A4">
                               <div class="col-12">
                                   <InputNumber v-model="data.F4"  mode="decimal" :minFractionDigits="2" :maxFractionDigits="6"></InputNumber>
                                </div>
                            </div>
                            <div v-else>
                                {{parseFloat(parseFloat(data.F4).toFixed(6))}}
                            </div>
						</template>
                    </Column>
                    <Column field="G4" header="TiO2 level" :style="{'min-width':'6rem',color:'blue'}">
                        <template #body="{data}">
							<div class="formgrid grid p-fluid"  v-if="productIdEdit==data.A4">
                               <div class="col-12">
                                   <InputNumber v-model="data.G4"  mode="decimal" :minFractionDigits="2" :maxFractionDigits="4"></InputNumber>
                                </div>
                            </div>
                            <div v-else>
                                {{parseFloat(parseFloat(data.G4).toFixed(4))}}
                            </div>
						</template>
                    </Column>
                    <Column field="H4" header="Refl" :style="{'min-width':'6rem',color:'blue'}">
                        <template #body="{data}">
							<div class="formgrid grid p-fluid"  v-if="productIdEdit==data.A4">
                               <div class="col-12">
                                   <InputNumber v-model="data.H4"  mode="decimal" :minFractionDigits="4" :maxFractionDigits="4"></InputNumber>
                                </div>
                            </div>
                            <div v-else>
                                {{parseFloat(data.H4).toFixed(4)}}
                            </div>
						</template>
                    </Column>
                    <Column field="I4" header="R ovr W" :style="{'min-width':'6rem',color:'blue'}">
                        <template #body="{data}">
							<div class="formgrid grid p-fluid"  v-if="productIdEdit==data.A4">
                               <div class="col-12">
                                   <InputNumber v-model="data.I4"  mode="decimal" :minFractionDigits="4" :maxFractionDigits="4"></InputNumber>
                                </div>
                            </div>
                            <div v-else>
                                {{parseFloat(data.I4).toFixed(4)}}
                            </div>
						</template>
                    </Column>
                    <Column field="J4" header="R ovr Blk" :style="{'min-width':'6rem',color:'blue'}">
                        <template #body="{data}">
							<div class="formgrid grid p-fluid"  v-if="productIdEdit==data.A4">
                               <div class="col-12">
                                   <InputNumber v-model="data.J4"  mode="decimal" :minFractionDigits="4" :maxFractionDigits="4"></InputNumber>
                                </div>
                            </div>
                            <div v-else>
                                {{parseFloat(data.J4).toFixed(4)}}
                            </div>
						</template>
                    </Column>


                    <Column field="K4" header="Rinf" :style="{'min-width':'6rem',color:'orangered'}" frozen>
                        <template #body="{data}">
							{{parseFloat(data.K4).toFixed(4)}}
						</template>
                    </Column>
                    <Column field="L4"  :style="{'min-width':'6rem',color:'orangered'}" >
                        <template #header>
                            <p>Thickness to <br/>reach full hiding <br/> (mm)</p>
                        </template>
                        <template #body="{data}">
							{{parseFloat(data.L4).toFixed(1)}}
						</template>
                    </Column>
                    <Column field="M4"  :style="{'min-width':'6rem' ,color:'orangered'}" >
                        <template #header>
                            <p>Spread rate <br/>&nbsp;(m<sup>2</sup>/l)</p>
                        </template>
                        <template #body="{data}">
							{{parseFloat(data.M4).toFixed(4)}}
						</template>
                    </Column>
                    <Column field="N4" :style="{'max-width':'6rem' ,color:'orangered'}" >
                        <template #header>
                            <p>S<sub>TiO2</sub></p>
                        </template>
                        <template #body="{data}">
							{{parseFloat(data.N4).toFixed(2)}}
						</template>
                    </Column>
                    
                    <!-- <Column field="O4" header="S/micron" :style="{'min-width':'6rem' ,color:'orangered'}">
                        <template #body="{data}">
							{{parseFloat(data.O4).toFixed(4)}}
						</template>
                    </Column>
                    
                    <Column field="P4" header="D4" :style="{width:'200px',color:'mediumseagreen'}"></Column>
                    <Column field="Q4" header="D4" :style="{width:'200px',color:'mediumseagreen'}"></Column>
                    <Column field="S4" header="D4" :style="{width:'200px',color:'mediumseagreen'}"></Column>
                    <Column field="T4" header="D4" :style="{width:'200px',color:'mediumseagreen'}"></Column>
                    <Column field="U4" header="D4" :style="{width:'200px',color:'mediumseagreen'}"></Column>
                    <Column field="V4" header="D4" :style="{width:'200px',color:'green'}"></Column>
                    <Column field="W4" header="D4" :style="{width:'200px'}"></Column>
                    <Column field="X4" header="D4" :style="{width:'200px'}"></Column>
                    <Column field="Y4" header="D4" :style="{width:'200px'}"></Column>
                    <Column field="Z4" header="D4" :style="{width:'200px'}"></Column>
                    <Column field="AA4" header="D4" :style="{width:'200px'}"></Column>
                    <Column field="AB4" header="D4" :style="{width:'200px'}"></Column>
                    <Column field="AC4" header="D4" :style="{width:'200px'}"></Column>
                    <Column field="AD4" header="D4" :style="{width:'200px'}"></Column>
                    <Column field="AE4" header="D4" :style="{width:'200px'}"></Column>
                    <Column field="AF4" header="D4" :style="{width:'200px'}"></Column>
                    <Column field="AG4" header="D4" :style="{width:'200px'}"></Column>
                    <Column field="AH4" header="D4" :style="{width:'200px'}"></Column>
                    <Column field="AI4" header="D4" :style="{width:'200px'}"></Column>
                    <Column field="AJ4" header="D4" :style="{width:'200px'}"></Column>
                    <Column field="AK4" header="D4" :style="{width:'200px'}"></Column>
                    <Column field="AL4" header="D4" :style="{width:'200px'}"></Column>
                    <Column field="AM4" header="D4" :style="{width:'200px'}"></Column>
                    <Column field="AN4" header="D4" :style="{width:'200px'}"></Column>
                    <Column field="AO4" header="D4" :style="{width:'200px'}"></Column>
                    <Column field="AP4" header="D4" :style="{width:'200px'}"></Column>
                    <Column field="AQ4" header="D4" :style="{width:'200px'}"></Column>
                    <Column field="AR4" header="D4" :style="{width:'200px'}"></Column>
                    <Column field="AS4" header="D4" :style="{width:'200px'}"></Column>
                    <Column field="AT4" header="D4" :style="{width:'200px'}"></Column>
                    <Column field="AU4" header="D4" :style="{width:'200px'}"></Column>
                    <Column field="AV4" header="D4" :style="{width:'200px'}"></Column>
                    <Column field="AW4" header="D4" :style="{width:'200px'}"></Column>
                    <Column field="AX4" header="D4" :style="{width:'200px'}"></Column>
                    <Column field="AY4" header="D4" :style="{width:'200px'}"></Column>
                    <Column field="AZ4" header="D4" :style="{width:'200px'}"></Column>
                    <Column field="BA4" header="D4" :style="{width:'200px'}"></Column> -->

                    
                   
                </DataTable>

        
                
			</div>
		</div>

      


		

		
	</div>


                <Dialog v-model:visible="deleteProductDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="product">Are1 you sure you want to delete <b>{{product.name}}</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false"/>
						<Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProduct" />
					</template>
				</Dialog>
</template>

<script>
    import SrateService from "../../service/SrateService";
	export default {
		data() {
			return {
				customer2: null,
				loading1: true,
				loading2: true,
                productDialog: false,
                deleteProductDialog: false,
                productToBeDeleted: -1,
                product: {},
                new_disabled: true,
                productIdEdit:null,
                //products:[{"'[srate.xlsx]SETTINGS'!B2":"Spread Rate Calculator v1.0 Oct 2013","'[srate.xlsx]SETTINGS'!B4":"Setting","'[srate.xlsx]SETTINGS'!B5":"Correct for Substrate Specular Reflectance","'[srate.xlsx]SETTINGS'!B6":"Contrast Ratio at Complete Hiding","'[srate.xlsx]SETTINGS'!B8":"Spread Rate Target, m2/liter","'[srate.xlsx]SETTINGS'!B9":"Substrate Reflectance at Spread Rate Target","'[srate.xlsx]SETTINGS'!C4":"Value","'[srate.xlsx]SETTINGS'!C5":0.96,"'[srate.xlsx]SETTINGS'!C6":0.98,"'[srate.xlsx]SETTINGS'!C8":20,"'[srate.xlsx]SETTINGS'!C9":0.8,"'[srate.xlsx]SETTINGS'!D4":"Comments","'[srate.xlsx]SETTINGS'!D5":"Set Value = 1.00 for 'Un-Corrected' value.  For glossy opacity charts, use a value of 0.96 to correct for substrate specular reflectance..","A4":1,"AA4":"4.356282894028471","AB4":"21.32465134099617","AC4":"4.356282894028471","AD4":"0.0928962138579029","AE4":"0.928962138579029","AF4":"0.912189203458832","AG4":"1.0042265003575777","AH4":"0.09203729689874561","AI4":"-1.9919350863032175","AJ4":"1.1278466934799478","AK4":"0.9047401292784374","AL4":"0.9047401292784374","AM4":"0.8866453266928687","AN4":"0.845625","AO4":"1.0042265003575777","AP4":"0.09203729689874561","AQ4":"8.089293300246764","AR4":"#NUM!","AS4":"#VALUE!","AT4":"10.436398091925849","AV4":"10.436398091925849","AW4":"112.34470877241246","AX4":"8.901175773447388","AY4":"11.234470877241247","AZ4":"0.912189203458832","B4":"LA21083_20","BA4":"0.0042265003575776205","C4":"BOSS","D4":2.61,"E4":386,"F4":1.4419,"G4":100,"H4":0.8118,"I4":0.8873,"J4":0.8026,"K4":"0.912189203458832","L4":"112.34470877241246","M4":"8.901175773447388","N4":"4.689408441007062","O4":"0.0928962138579029","P4":"0.845625","Q4":"0.98","S4":"0.9045418685901048","T4":"46.89408441007062","U4":"4.689408441007062","V4":"1.0042265003575777","W4":"0.09203729689874561","X4":"4.13694684062785","Y4":"#NUM!","Z4":"#VALUE!"}]
                products:[]
            }
		},
		productService: null,
        srateService:null,
		created() {
            this.srateService = new SrateService();
		},
		mounted() {
			
			this.srateService.getSrateData().then((data) => {
                this.products = data.data
                this.loading2 = false;
                this.new_disabled=false;
            });
			
		},
		methods: {
			
			formatCurrency(value) {
				return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
			},
			openNew() {                
                this.productIdEdit=((new Date()).getTime())
                this.product = {"A4":this.productIdEdit,"B4":"d_1","C4":"BOSS","D4":2.61,"E4":386,"F4":1.4419,"G4":100,"H4":0.8118,"I4":0.8873,"J4":0.8026}
                this.products.push(this.product);                
                this.new_disabled = true;
                //this.productDialog = true;
            },
           
            editProduct(id){
                this.productIdEdit=id;
                this.new_disabled = true;
                this.product=this.findIndexById(id);
            },
            saveProduct() {
                this.new_disabled = true;
                this.loading2 = true;
                this.productDialog = false;
                console.log(this.product);
                if (this.product.A4) {                    
                    this.srateService.save(this.product)
                                .then((res) => {
                                    console.log(res);
                                    this.srateService.getSrateData().then((data) => {
                                        this.products = data.data;
                                        this.$toast.add({severity:'success', summary: 'Successful', detail: 'Record Saved!', life: 3000});
                                    });
                                }).catch(error => {
                                    console.log(error);
                                    this.$toast.add({severity:'error', summary: 'Error', detail: error.message, life: 3000});
                                }).finally(()=> {
                                    this.loading2 = false;
                                    this.new_disabled = false;
                                    this.product = {};
                                    this.productIdEdit=-1;
                                });        
                        
                }
            },
            confirmDeleteProduct(product) {
			this.productToBeDeleted = product;
			this.deleteProductDialog = true;
            },
            deleteSelectedProduct() {
                this.deleteProductDialog = false;               
                this.srateService.delete(this.productToBeDeleted)
                                .then((res) => {
                                    console.log(res);
                                        this.productToBeDeleted = -1;
                                        this.srateService.getSrateData().then((data) => {
                                        this.products = data.data;
                                        this.$toast.add({severity:'success', summary: 'Successful', detail: 'Record Deleted ', life: 3000});
                                    });
                                }).catch(error => {
                                    console.log(error);
                                    this.$toast.add({severity:'error', summary: 'Error', detail: error.message, life: 3000});
                                }).finally(()=> {
                                    this.loading2 = false;
                                    this.new_disabled = false;
                                    this.product = {};
                                    this.productIdEdit=-1;
                                    this.productToBeDeleted = -1;
                                });        
            },
            findIndexById(id) {
                let index = -1;
                for (let i = 0; i < this.products.length; i++) {
                    if (this.products[i]["A4"] === id) {
                        index = i;
                        break;
                    }
                }
                return this.products[index];
            },
            cancelEdit(){
                 this.productIdEdit=null;
                 this.new_disabled=false;
                 if(!this.products[this.products.length-1]["K4"]>0){
                     this.products.pop();                     
                 }                 
            },
            exportCSV() {
			this.$refs.dt.exportCSV();
		},
		}
	}
</script>

<style scoped lang="scss">
	.customer-badge {
		border-radius: 2px;
		padding: .25em .5rem;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 12px;
		letter-spacing: .3px;

		&.status-qualified {
			background: #C8E6C9;
			color: #256029;
		}

		&.status-unqualified {
			background: #FFCDD2;
			color: #C63737;
		}

		&.status-negotiation {
			background: #FEEDAF;
			color: #8A5340;
		}

		&.status-new {
			background: #B3E5FC;
			color: #23547B;
		}

		&.status-renewal {
			background: #ECCFFF;
			color: #694382;
		}

		&.status-proposal {
			background: #FFD8B2;
			color: #805B36;
		}
	}

	.product-badge {
		border-radius: 2px;
		padding: .25em .5rem;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 12px;
		letter-spacing: .3px;

		&.status-instock {
			background: #C8E6C9;
			color: #256029;
		}

		&.status-outofstock {
			background: #FFCDD2;
			color: #C63737;
		}

		&.status-lowstock {
			background: #FEEDAF;
			color: #8A5340;
		}
	}

	.order-badge {
		border-radius: 2px;
		padding: .25em .5rem;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 12px;
		letter-spacing: .3px;

		&.order-delivered {
			background: #C8E6C9;
			color: #256029;
		}

		&.order-cancelled {
			background: #FFCDD2;
			color: #C63737;
		}

		&.order-pending {
			background: #FEEDAF;
			color: #8A5340;
		}

		&.order-returned {
			background: #ECCFFF;
			color: #694382;
		}
	}
</style>